import {
  FilterOption,
  FilterViewModel,
} from '../../../ViewModel/filterViewModel/filterViewModel';

export const getSelectedOptionsFromList = (list: FilterOption[]) =>
  list.filter((option) => option.selected);

export const optionsHaveChildren = (options: FilterOption[]) =>
  options[0]?.children !== undefined;

export function getSelectedOptions({ options }: FilterViewModel) {
  return optionsHaveChildren(options)
    ? options.reduce(
        (acc, option) =>
          acc.concat(getSelectedOptionsFromList(option.children!)),
        [] as FilterOption[],
      )
    : getSelectedOptionsFromList(options);
}
