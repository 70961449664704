import { LayoutOptions } from '../types/types';

export const timeSlotLayouts = [
  LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER,
  LayoutOptions.DAILY_TIME_SLOTS_WEEKLY_PICKER,
  LayoutOptions.WEEKLY_TIME_SLOTS,
];

export const isLayoutWithTimeSlot = (
  settings: any,
  settingsParams: any,
): boolean =>
  timeSlotLayouts.includes(settings.get(settingsParams.calendarLayout));

export const isDailyTimeSlotsMonthlyPickerLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;

export const isDailyTimeSlotsWeeklyPickerLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_TIME_SLOTS_WEEKLY_PICKER;

export const isDailyAgendaWeeklyPickerLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER;

export const isWeeklyTimeSlotsLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.WEEKLY_TIME_SLOTS;

export const isWeeklyTimetableLayout = (
  settings: any,
  settingsParams: any,
): boolean =>
  settings.get(settingsParams.calendarLayout) ===
  LayoutOptions.WEEKLY_TIMETABLE;
