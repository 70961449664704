import React from 'react';
import { SectionNotification } from 'wix-ui-tpa/cssVars';
import { Error } from '@wix/wix-ui-icons-common/on-stage';
import { classes } from './ErrorNotification.st.css';

export interface ErrorNotificationProps {
  errorText?: string;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ errorText }) => {
  return (
    <>
      {errorText ? (
        <SectionNotification
          type="error"
          className={classes.root}
          data-hook="error-notification"
        >
          <SectionNotification.Icon icon={<Error />} />
          <SectionNotification.Text className={classes.text}>
            {errorText}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
    </>
  );
};

export default ErrorNotification;
