import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  isWeeklyTimetableLayout,
  isDailyAgendaWeeklyPickerLayout,
  isLayoutWithTimeSlot,
} from '../../../../../utils/layouts';
import { useSettingsParams } from '../../../Hooks/useSettingsParams';
import {
  WEEKLY_TIMETABLE_DATE_PICKER_HEIGHT,
  MOBILE_DAILY_AGENDA_DATE_PICKER_HEIGHT,
  DESKTOP_DAILY_AGENDA_DATE_PICKER_HEIGHT,
  averageNumOfSlots,
  TIMETABLE_SLOT_HEIGHT,
  DAILY_AGENDA_SLOT_HEIGHT,
  STRETCHED_DAILY_AGENDA_SLOT_HEIGHT,
  MOBILE_CALENDAR_HEIGHT,
  DESKTOP_CALENDAR_HEIGHT,
  DAILY_AGENDA_STRETCHED_FROM_WIDTH,
  SHOW_ALL_SESSIONS_BUTTON_HEIGHT,
} from './constants';

export type UseWidgetLoaderHeight = typeof useWidgetLoaderHeight;
export const useWidgetLoaderHeight = () => {
  const {
    isMobile,
    dimensions: { width },
    isEditorX,
  } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const withDatePickerPlaceholder = !isLayoutWithTimeSlot(
    settings,
    settingsParams,
  );

  const isLimitSlotsEnabled = settings.get(
    settingsParams.limitTimeSlotsDisplay,
  );
  const userNumberOfSlots = settings.get(
    settingsParams.maxTimeSlotsDisplayedPerDay,
  );

  const numberOfSlots = isLimitSlotsEnabled
    ? Math.min(averageNumOfSlots, userNumberOfSlots)
    : averageNumOfSlots;
  const isWeeklyTimetable = isWeeklyTimetableLayout(settings, settingsParams);
  const isDailyAgenda = isDailyAgendaWeeklyPickerLayout(
    settings,
    settingsParams,
  );

  const getDatePickerPlaceholderHeight = () => {
    if (isWeeklyTimetable) {
      return WEEKLY_TIMETABLE_DATE_PICKER_HEIGHT;
    }

    if (isDailyAgenda) {
      return isMobile
        ? MOBILE_DAILY_AGENDA_DATE_PICKER_HEIGHT
        : DESKTOP_DAILY_AGENDA_DATE_PICKER_HEIGHT;
    }

    return 0;
  };

  const getSlotsHeight = () => {
    if (isWeeklyTimetable) {
      return numberOfSlots * TIMETABLE_SLOT_HEIGHT;
    }

    if (isDailyAgenda) {
      const dailyAgendaSlotHeight =
        isEditorX || width > DAILY_AGENDA_STRETCHED_FROM_WIDTH
          ? STRETCHED_DAILY_AGENDA_SLOT_HEIGHT
          : DAILY_AGENDA_SLOT_HEIGHT;

      return numberOfSlots * dailyAgendaSlotHeight;
    }

    return isMobile ? MOBILE_CALENDAR_HEIGHT : DESKTOP_CALENDAR_HEIGHT;
  };

  const datePickerHeight = withDatePickerPlaceholder
    ? getDatePickerPlaceholderHeight()
    : 0;

  const slotsHeight = getSlotsHeight();

  const showAllSessionsButtonHeight =
    isMobile && isDailyAgenda && isLimitSlotsEnabled && userNumberOfSlots <= 1
      ? SHOW_ALL_SESSIONS_BUTTON_HEIGHT
      : 0;

  return {
    loaderHeight: datePickerHeight + slotsHeight + showAllSessionsButtonHeight,
    shouldUseAsPlaceholder: !!showAllSessionsButtonHeight,
  };
};
