import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { TimeSlot } from '../../ViewModel/timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';

export const useTimeSlotSelectionProps = () => {
  const { onTimeSelected, onElementClicked } = useCalendarActions();

  const isTimeSlotFull = (timeSlot: TimeSlot) => {
    const { allSlotsAreFull, withWaitingList } = timeSlot.status;
    return allSlotsAreFull && !withWaitingList;
  };

  const isTimeSlotDisabled = (timeSlot: TimeSlot) => {
    const { tooEarlyToBookAllSlots } = timeSlot.status;
    return isTimeSlotUnavailable(timeSlot) || tooEarlyToBookAllSlots;
  };

  const isTimeSlotUnavailable = (timeSlot: TimeSlot) => {
    const { tooLateToBookAllSlots } = timeSlot.status;
    return isTimeSlotFull(timeSlot) || tooLateToBookAllSlots;
  };

  const onTimeSlotChanged = ({ id }: { id: string }) => {
    onTimeSelected(id);
  };

  const isAllSlotsNotBookable = (timeSlots: TimeSlot[]) =>
    timeSlots.every(
      (timeSlot) =>
        isTimeSlotDisabled(timeSlot) || isTimeSlotUnavailable(timeSlot),
    );

  return {
    isTimeSlotFull,
    isTimeSlotDisabled,
    onTimeSlotChanged,
    isTimeSlotUnavailable,
    isAllSlotsNotBookable,
  };
};
