import {
  createSettingsParam,
  createSettingsParams,
  ISettingsParam,
  PartialBy,
  SettingsParamType,
} from '@wix/tpa-settings';
import {
  AlignmentOptions,
  ServiceColor,
  LayoutOptions,
  SlotsAvailability,
  SubtitleSourceOptions,
  TimeSlotComponent,
} from '../../types/types';
import { AccessibilityHtmlTags } from '../../utils/accessibility/constants';
import { InitializeCalendarDateOptions } from '../../utils/bi/consts';
import { timeSlotLayouts } from '../../utils/layouts';

export const withoutDefaultFallback = (settingsParam: ISettingsParam) => ({
  ...settingsParam,
  getDefaultValue: () => '',
});

export type ISettingsParams = {
  columnAlignment: SettingsParamType.String;
  textAlignment: AlignmentOptions;
  slotAlignment: AlignmentOptions;
  calendarLayout: LayoutOptions;
  selectedLocations: SettingsParamType.Text[];
  selectedCategories: SettingsParamType.Text[];
  selectedService: SettingsParamType.String;
  dateAndTimeSectionHeader: SettingsParamType.String;
  sectionHeaderHtmlTag: SettingsParamType.String;
  bookingDetailsSectionHeader: SettingsParamType.String;
  headerVisibility: SettingsParamType.Boolean;
  headerTitleVisibility: SettingsParamType.Boolean;
  headerSubtitleVisibility: SettingsParamType.Boolean;
  headerFiltersVisibility: SettingsParamType.Boolean;
  headerServiceFilterVisibility: SettingsParamType.Boolean;
  headerStaffFilterVisibility: SettingsParamType.Boolean;
  headerLocationFilterVisibility: SettingsParamType.Boolean;
  headerTimezoneSelectionVisibility: SettingsParamType.Boolean;
  dateAndTimeTimezoneSelectionVisibility: SettingsParamType.Boolean;
  headerSubtitleSource: SubtitleSourceOptions;
  headerTitle: SettingsParamType.String;
  headerTitleHtmlTag: SettingsParamType.String;
  headerSubtitle: SettingsParamType.String;
  noSessionsOffered: SettingsParamType.String;
  noUpcomingTimeSlots: SettingsParamType.String;
  goToNextAvailableDate: SettingsParamType.String;
  fullyBookedDateNotification: SettingsParamType.String;
  loadMoreTimeSlots: SettingsParamType.String;
  limitTimeSlotsDisplay: SettingsParamType.Boolean;
  maxTimeSlotsDisplayedPerDay: SettingsParamType.Number;
  videoConferenceBadgeVisibility: SettingsParamType.Boolean;
  timeslotDetailsVisibility: SettingsParamType.Boolean;
  videoConferenceBadge: SettingsParamType.String;
  preferencesTitle: SettingsParamType.String;
  serviceLabel: SettingsParamType.String;
  staffMemberLabel: SettingsParamType.String;
  locationLabel: SettingsParamType.String;
  durationLabel: SettingsParamType.String;
  bookingDetailsPricingPlanText: SettingsParamType.String;
  bookingDetailsClearText: SettingsParamType.String;
  nextButton: SettingsParamType.String;
  noSpotsLeft: SettingsParamType.String;
  toEarlyToBookIndication: SettingsParamType.String;
  toLateToBookIndication: SettingsParamType.String;
  pendingApprovalButton: SettingsParamType.String;
  joinWaitlistButton: SettingsParamType.String;
  rescheduleButton: SettingsParamType.String;
  slotsAvailability: SlotsAvailability;
  initializeCalendarDate: InitializeCalendarDateOptions;
  waitlistIndication: SettingsParamType.String;
  buttonsFullWidth: SettingsParamType.Boolean;
  slotLocationVisibility: SettingsParamType.Boolean;
  slotDurationVisibility: SettingsParamType.Boolean;
  slotStaffMemberVisibility: SettingsParamType.Boolean;
  slotPriceVisibility: SettingsParamType.Boolean;
  spotsLeftVisibility: SettingsParamType.Boolean;
  slotRegistrationStatusVisibility: SettingsParamType.Boolean;
  spotsLeftFormat: SettingsParamType.Text;
  ctaVisibility: SettingsParamType.Boolean;
  useColorPerService: SettingsParamType.Boolean;
  servicesColorsType: ServiceColor;
  sideBordersVisibility: SettingsParamType.Boolean;
  timeSlotComponent: TimeSlotComponent;
  calendarHeaderTitle: SettingsParamType.String;
};
const calendarLayout = createSettingsParam('calendarLayout', {
  getDefaultValue: ({ isMobile }) =>
    isMobile
      ? LayoutOptions.DAILY_TIME_SLOTS_WEEKLY_PICKER
      : LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER,
  inheritDesktop: false,
});

const headerFiltersVisibility = createSettingsParam('headerFiltersVisibility', {
  type: SettingsParamType.Boolean,
  getDefaultValue: () => true,
});

export type ISettingsParamsDefinitions = {
  [key in keyof ISettingsParams]: PartialBy<
    ISettingsParam<ISettingsParams[key]>,
    'key'
  >;
};

export const settingsParamsDefinitions: ISettingsParamsDefinitions = {
  columnAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  textAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  slotAlignment: {
    inheritDesktop: false,
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  calendarLayout: calendarLayout as any,
  selectedLocations: {
    type: SettingsParamType.Object,
    getDefaultValue: () => [],
  },
  selectedCategories: {
    type: SettingsParamType.Object,
    getDefaultValue: () => [],
  },
  selectedService: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
  dateAndTimeSectionHeader: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.widget.date-and-time-header'),
  },
  sectionHeaderHtmlTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
  },
  bookingDetailsSectionHeader: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.widget.booking-details-header'),
  },
  headerTitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerTitleHtmlTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => AccessibilityHtmlTags.MainHeading,
  },
  headerSubtitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerFiltersVisibility,
  headerServiceFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerStaffFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }) =>
      getSettingParamValue(headerFiltersVisibility) ?? true,
  },
  headerLocationFilterVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ getSettingParamValue }) =>
      getSettingParamValue(headerFiltersVisibility) ?? true,
  },
  headerTimezoneSelectionVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ isMobile, getSettingParamValue }) => {
      const layout =
        getSettingParamValue(calendarLayout) ||
        LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;

      return timeSlotLayouts.includes(layout) ? isMobile : false;
    },
  },
  dateAndTimeTimezoneSelectionVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerSubtitleSource: {
    getDefaultValue: () => SubtitleSourceOptions.CUSTOM,
  },
  calendarHeaderTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.header-title-appointment'),
  },
  headerTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.header-title'),
  },
  headerSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.header-subtitle'),
  },
  noSessionsOffered: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.time-picker.no-slots-message'),
  },

  noUpcomingTimeSlots: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.time-picker.notifications.no-upcoming-slots'),
  },
  goToNextAvailableDate: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.time-picker.go-to-next-available-day'),
  },
  fullyBookedDateNotification: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t(
        'app.settings.defaults.time-picker.notifications.all-sessions-are-full',
      ),
  },
  loadMoreTimeSlots: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, getSettingParamValue, isMobile }) =>
      getSettingParamValue(calendarLayout, { isMobile }) ===
      LayoutOptions.WEEKLY_TIME_SLOTS
        ? t('app.settings.defaults.week-availability.show-all')
        : t('app.settings.defaults.time-picker.show-all'),
  },
  limitTimeSlotsDisplay: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  maxTimeSlotsDisplayedPerDay: {
    type: SettingsParamType.Number,
    getDefaultValue: ({ getSettingParamValue }) => {
      const layout =
        getSettingParamValue(calendarLayout) ||
        LayoutOptions.DAILY_TIME_SLOTS_MONTHLY_PICKER;
      return layout === LayoutOptions.WEEKLY_TIME_SLOTS ? 5 : 10;
    },
  },
  preferencesTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.preferences.title'),
  },
  serviceLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.service-label'),
  },
  staffMemberLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.staff-member-label'),
  },
  locationLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.location-label'),
  },
  durationLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.duration-label'),
  },
  bookingDetailsPricingPlanText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pricing-plan.title'),
  },
  bookingDetailsClearText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.clear.title'),
  },
  nextButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t, getSettingParamValue, isMobile }) =>
      timeSlotLayouts.includes(
        getSettingParamValue(calendarLayout, { isMobile }),
      )
        ? t('app.settings.defaults.booking-details.book-now.text')
        : t('app.settings.defaults.button.book'),
  },
  toEarlyToBookIndication: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.policy.to-early-to-book'),
  },
  noSpotsLeft: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.slots.no-spots-left'),
  },
  toLateToBookIndication: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.policy.to-late-to-book'),
  },
  rescheduleButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.rescheduled-booking.booking-details.cta'),
  },
  pendingApprovalButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pending-approval.text'),
  },
  joinWaitlistButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.join-waitlist.text'),
  },
  videoConferenceBadgeVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  timeslotDetailsVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  videoConferenceBadge: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.video-conference-badge-text'),
  },
  slotsAvailability: {
    getDefaultValue: () => SlotsAvailability.ALL,
  },
  initializeCalendarDate: {
    getDefaultValue: () => InitializeCalendarDateOptions.TODAY,
  },
  waitlistIndication: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.waitlist'),
  },
  buttonsFullWidth: {
    type: SettingsParamType.Boolean,
    inheritDesktop: false,
    getDefaultValue: () => true,
  },
  slotLocationVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  slotRegistrationStatusVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  spotsLeftVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotPriceVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  slotStaffMemberVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  slotDurationVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  spotsLeftFormat: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.slots.spots-left'),
  },
  ctaVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  useColorPerService: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
    inheritDesktop: false,
  },
  servicesColorsType: {
    getDefaultValue: () => ServiceColor.BACKGROUND,
  },
  sideBordersVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  timeSlotComponent: {
    getDefaultValue: () => TimeSlotComponent.BUTTONS,
  },
};

export default createSettingsParams<ISettingsParams>(settingsParamsDefinitions);
