import {
  createSettingsParam,
  createSettingsParams,
  ISettingsParam,
  PartialBy,
  SettingsParamType,
} from '@wix/tpa-settings';
import calendarSettingsParams, {
  ISettingsParams,
} from '../BookingCalendar/settingsParams';
import { AlignmentOptions, LayoutOptions } from '../../types/types';
import { AccessibilityHtmlTags } from '../../utils/accessibility/constants';

type WeeklyTimetableSettingsOverride = Pick<
  ISettingsParams,
  | 'textAlignment'
  | 'calendarLayout'
  | 'headerTitleHtmlTag'
  | 'headerServiceFilterVisibility'
  | 'headerStaffFilterVisibility'
  | 'headerLocationFilterVisibility'
  | 'headerTimezoneSelectionVisibility'
  | 'headerSubtitleVisibility'
  | 'limitTimeSlotsDisplay'
  | 'maxTimeSlotsDisplayedPerDay'
  | 'noSessionsOffered'
  | 'loadMoreTimeSlots'
  | 'nextButton'
>;

export type IWeeklyTimetableSettingsDefinitions = {
  [key in keyof WeeklyTimetableSettingsOverride]: PartialBy<
    ISettingsParam<WeeklyTimetableSettingsOverride[key]>,
    'key'
  >;
};

const calendarLayout = createSettingsParam('calendarLayout', {
  getDefaultValue: ({ isMobile }) =>
    isMobile
      ? LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER
      : LayoutOptions.WEEKLY_TIMETABLE,
});

export const weeklyTimetableSettingsParamsDefinitions: IWeeklyTimetableSettingsDefinitions =
  {
    calendarLayout: calendarLayout as any,
    textAlignment: {
      getDefaultValue: () => AlignmentOptions.CENTER,
    },
    headerTitleHtmlTag: {
      getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
    },
    headerServiceFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => true,
    },
    headerStaffFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => true,
    },
    headerLocationFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
    headerTimezoneSelectionVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
    headerSubtitleVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
    maxTimeSlotsDisplayedPerDay: {
      getDefaultValue: () => 1,
    },
    limitTimeSlotsDisplay: {
      getDefaultValue: ({ isMobile }) => isMobile,
    },
    noSessionsOffered: {
      type: SettingsParamType.Text,
      getDefaultValue: ({ t, getSettingParamValue, isMobile }) =>
        getSettingParamValue(calendarLayout, { isMobile }) ===
        LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER
          ? t('app.settings.defaults.slots.no-availability')
          : t('app.settings.defaults.time-picker.no-slots-message'),
    },
    loadMoreTimeSlots: {
      type: SettingsParamType.Text,
      getDefaultValue: ({ t, getSettingParamValue, isMobile }) =>
        getSettingParamValue(calendarLayout, { isMobile }) ===
        LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER
          ? t('app.settings.defaults.time-picker.daily-agenda.show-all')
          : t('app.settings.defaults.time-picker.show-all'),
    },
    nextButton: {
      type: SettingsParamType.Text,
      getDefaultValue: ({ t }) => t('app.settings.defaults.button.book'),
    },
  };

const weeklyTimetableSettingsParams =
  createSettingsParams<WeeklyTimetableSettingsOverride>(
    weeklyTimetableSettingsParamsDefinitions,
  );

export default {
  ...calendarSettingsParams,
  ...weeklyTimetableSettingsParams,
};
