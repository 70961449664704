export enum BookingDetailsDataHooks {
  BOOKING_DETAILS_SELECTION_WRAPPER = 'booking-details-wrapper',
  TIME_SLOT_DETAILS = 'time-slot-details',
  SLOT_NAME = 'slot-name',
  SLOT_DETAILS = 'slot-details',
  SLOT_DATE_AND_TIME = 'slot-date-and-time',
  SLOT_PLAN_TYPE = 'slot-plan-type',
  DIVIDER = 'divider',
  SLOT_PREFERENCES_WRAPPER = 'slot-preferences-wrapper',
  SLOT_PREFERENCES_TITLE = 'slot-preferences-title',
  CLEAR_BUTTON = 'clear-button',
  VIDEO_CONFERENCE_BADGE = 'video-conference-badge',
  SERVICE_DETAILS = 'service-details',
  DROPDOWN = 'dropdown',
  ALERT = 'alert',
  Title = 'service-details-title',
  Divider = 'service-details-divider',
}
