export enum FiltersDataHooks {
  FILTERS_WRAPPER = 'filter-wrapper',
  MOBILE_FILTERS_WRAPPER = 'mobile-filter-wrapper',
  FILTER_DIVIDER = 'filter-header-divider',
  LABEL = 'filter-label',
  FILTER_LIMITATION_TEXT = 'filter-limitation-text',
  FILTER_CTA = 'filter-cta',
  FILTER_MODAL_CTA = 'filter-modal-cta',
  FILTER_DIALOG = 'filter-dialog',
  CLEAR_FILTERS_CTA = 'clear-filters-cta',
  FILTERS_ACCORDION = 'filters-accordion',
  CLEAR_DIALOG_BUTTON = 'clear-dialog-button',
  APPLY_DIALOG_BUTTON = 'apply-dialog-button',
  OPTION = 'filter-option',
  NOTE_DIVIDER = 'filters-note-divider',
  NOTE = 'filters-note',
  ALL_SR_LABEL = 'filters-sr-All',
  FILTERS_CONTAINER = 'filters-container',
}
