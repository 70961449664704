import { Preset } from '../../types/types';

export const RelatedPages = {
  BookingForm: 'booking_form_widget',
  ServicePage: 'booking_service_page_widget',
};

export enum WidgetComponents {
  TIME_PICKER = 'time_picker',
  DATE_PICKER = 'date_picker',
  BOOKING_DETAILS = 'booking_details',
  TIMEZONE_PICKER = 'timezone_picker',
  RESCHEDULE_DIALOG = 'reschedule_dialog',
  JOIN_WAITLIST_DIALOG = 'join_waitlist_dialog',
  PREMIUM_VIEWER_DIALOG = 'premium_viewer_dialog',
  NON_ECOM_SITE_VIEWER_DIALOG = 'non_ecom_site_viewer_dialog',
  FILTER = 'filter',
  CHANGE_SERVICE = 'change_service',
}

export enum WidgetElements {
  TIME_SLOT = 'time_slot',
  MONTH_ARROW = 'month_arrow',
  WEEK_ARROW = 'week_arrow',
  DATE_IN_MONTH = 'date_in_month',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'go_to_next_available_date_link',
  CONFIRM_BUTTON = 'confirm_button',
  DROPDOWN = 'dropdown',
  DIALOG_X_BUTTON = 'close_button',
  CHECKBOX = 'checkbox',
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
  COUNTER = 'counter',
  CHECKBOX_ALL = 'checkbox_all',
  FILTER_BUTTON = 'filter_button',
  CLEAR_BUTTON = 'clear_button',
  SHOW_ALL_SESSIONS_BUTTON = 'show_all_sessions_button',
  DIALOG_CLOSE_BUTTON = 'dialog_close_button',
  MENU_OPTION = 'menu_option',
  CHANGE_SERVICE_BUTTON = 'change_service_button',
  OPEN_FILTERS = 'open_filters',
  CLOSE_FILTERS = 'close_filters',
  FILTERS_ACCORDION_ITEM = 'filters_accordion_item',
  CLEAR = 'clear',
  APPLY = 'apply',
  FILTER_DROPDOWN = 'filter_dropdown',
  TIME_SLOT_NOTIFICATION = 'time_slot_notification',
}

export enum InitializeCalendarDateOptions {
  FIRST_AVAILABLE_DATE = 'first_available_date',
  TODAY = 'today',
}

export type TimeSlotsAvailability = {
  AVAILABLE: number;
  FULL: number;
  TOO_LATE_TO_BOOK: number;
  TOO_EARLY_TO_BOOK: number;
  WAITLIST: number;
};

export enum WidgetName {
  PAGE = 'calendar-page',
  WIDGET = 'calendar-widget',
  DAILY_AGENDA = 'daily-agenda',
  WEEKLY_TIMETABLE = 'weekly-timetable',
}

export const PresetToWidgetNameMap: Record<Preset, WidgetName> = {
  [Preset.CALENDAR_PAGE]: WidgetName.PAGE,
  [Preset.CALENDAR_WIDGET]: WidgetName.WIDGET,
  [Preset.WEEKLY_TIMETABLE_WIDGET]: WidgetName.WEEKLY_TIMETABLE,
  [Preset.DAILY_AGENDA_WIDGET]: WidgetName.DAILY_AGENDA,
};
