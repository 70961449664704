import React from 'react';
import { SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { WidgetLoaderDataHooks } from './constants';
import { classes } from './WidgetLoader.st.css';

export interface WidgetLoaderProps {
  spinnerType?: SpinnerTypes;
  minHeight?: number;
}

export const WidgetLoader: React.FC<WidgetLoaderProps> = ({
  spinnerType = SpinnerTypes.regular,
  minHeight,
}) => {
  return (
    <div
      data-hook={WidgetLoaderDataHooks.ROOT}
      className={classes.root}
      style={{ minHeight }}
    >
      <SpinnerWrapper
        data-hook={WidgetLoaderDataHooks.SPINNER}
        diameter={50}
        className={classes.spinner}
        type={spinnerType}
      />
    </div>
  );
};
