import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  Badge,
  BadgePriority as BADGE_PRIORITY,
  Divider,
  Dropdown,
  SectionNotification,
  SectionNotificationType as NOTIFICATION_TYPE,
  Text,
  TextButton,
  DropdownInputHandle,
} from 'wix-ui-tpa/cssVars';
import { MultiselectDropdown } from '@wix/bookings-viewer-ui';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { VideoFillXSmall as CameraSVG } from '@wix/wix-ui-icons-common/on-stage/system';
import { classes, st } from './BookingDetails.st.css';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { BookingPreference } from '../../../../utils/bookingPreferences/bookingPreferences';
import AlertIcon from '../../../../assets/Icons/notificationAlert.svg';
import { BookingDetailsViewModel } from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';
import { useCreateCssVarsClassContainer } from '../../Hooks/useCreateCssVarsClassContainer';
import { BookingDetailsDataHooks } from '../BookingDetails/constants';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

export type CollapsibleBookingDetailsProps = BookingDetailsProps & {
  isCollapsed: boolean | undefined;
  title: string;
};

const BookingDetails: React.FC<CollapsibleBookingDetailsProps> = ({
  viewModel,
  isCollapsed,
  title,
}) => {
  const { isMobile } = useEnvironment();
  const { onElementClicked } = useCalendarActions();
  const { preferences, alert } = viewModel;

  const bookingPreferences = preferences?.bookingPreferences;

  const onAccordionItemClick = (id: string, isExpanded: boolean) => {
    onElementClicked(
      WidgetComponents.BOOKING_DETAILS,
      isExpanded ? WidgetElements.EXPAND : WidgetElements.COLLAPSE,
    );
  };

  const shouldShowPreferences = () => {
    return bookingPreferences?.some(
      (bookingPreference: BookingPreference) =>
        bookingPreference.options.length > 1,
    );
  };

  const showAccordion = isCollapsed !== undefined;

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile })}
    >
      <div className={classes.serviceDetailsWrapper}>
        {showAccordion ? (
          <Accordion
            key={`details-${isCollapsed}`}
            className={classes.accordion}
            initiallyExpanded={isCollapsed ? '' : 'details'}
            onItemClick={onAccordionItemClick}
            data-hook={BookingDetailsDataHooks.SERVICE_DETAILS}
          >
            <Accordion.Item id="details" title={title}>
              <TimeSlotDetails viewModel={viewModel} />
            </Accordion.Item>
          </Accordion>
        ) : (
          <TimeSlotDetails viewModel={viewModel} />
        )}
      </div>

      {shouldShowPreferences() ? (
        <BookingPreferences
          viewModel={viewModel}
          showDivider={!showAccordion}
        />
      ) : null}
      <Alert alert={alert} />
    </div>
  );
};
export default BookingDetails;

const Alert = ({ alert }: { alert?: string }) => {
  return alert ? (
    <SectionNotification
      type={NOTIFICATION_TYPE.alert}
      data-hook={BookingDetailsDataHooks.ALERT}
      className={classes.alert}
    >
      <SectionNotification.Icon icon={<AlertIcon />} />
      <SectionNotification.Text>{alert}</SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

const TimeSlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    preferences,
    videoConferenceBadgeText,
  } = viewModel;

  const bookingPreferences = preferences?.bookingPreferences;

  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={classes.serviceNameAndDate}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={classes.videoConferenceBadge}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
          key="service-name"
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
          key="date-and-time"
        >
          {dateAndTime}
        </Text>
      </div>
      {!!bookingPreferences &&
        bookingPreferences?.map((bookingPreference) => {
          return bookingPreference.options.length === 1 ? (
            <Text
              data-hook={`${BookingDetailsDataHooks.TIME_SLOT_DETAILS}-${bookingPreference.key}`}
              className={classes.commonTextStyle}
              tagName={AccessibilityHtmlTags.Paragraph}
              aria-label={bookingPreference.options[0].ariaLabel}
              key={bookingPreference.key}
            >
              {bookingPreference.options[0].value}
            </Text>
          ) : null;
        })}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={classes.commonTextStyle}
        tagName={AccessibilityHtmlTags.Paragraph}
        key="payment-description"
      >
        {paymentDescription}
      </Text>
    </>
  );
};

type BookingPreferencesProps = BookingDetailsProps & {
  showDivider: boolean;
};

const BookingPreferences: FC<BookingPreferencesProps> = ({
  viewModel,
  showDivider,
}) => {
  const {
    onBookingPreferenceOptionSelected,
    onClearSelectedBookingPreferences,
    onElementClicked,
  } = useCalendarActions();
  const [forceDropdownRender, setForceDropdownRender] = useState(false);
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState<BookingPreference>();
  const { dateAndTime, preferences } = viewModel;
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const dropdownRefs = useRef<DropdownInputHandle[]>([]);

  const isCalendarA11YLabelsEnabled = experiments.enabled(
    'specs.bookings.A11YCalendarLabel',
  );
  const { rootRef, cssVarsContainerClassName } =
    useCreateCssVarsClassContainer();

  useEffect(() => {
    setClearButtonVisibility(false);
  }, [dateAndTime]);

  useEffect(() => {
    dropdownRefs.current = dropdownRefs.current.slice(
      0,
      preferences?.bookingPreferences.length,
    );
  }, [preferences?.bookingPreferences]);

  const clearDropdowns = () => {
    setForceDropdownRender(!forceDropdownRender);
    onClearSelectedBookingPreferences();
    setClearButtonVisibility(false);
    setFocusOnDropdown();
  };

  const setFocusOnDropdown = () => {
    setTimeout(() => {
      dropdownRefs.current[1].focus();
    }, 50);
  };

  const onDropdownChange = (
    selectedOptionId: string,
    bookingPreference: BookingPreference,
    numberOfParticipants: number,
    isMultipleChoices: boolean,
  ) => {
    onBookingPreferenceOptionSelected({
      key: bookingPreference.key,
      value: selectedOptionId,
      numberOfParticipants,
      isMultipleChoices,
    });
    setClearButtonVisibility(true);
  };

  const onDropdownClick = (bookingPreference: BookingPreference) => {
    onElementClicked(
      WidgetComponents.BOOKING_DETAILS,
      WidgetElements.DROPDOWN,
      bookingPreference.key,
    );
    setSelectedDropDown(bookingPreference);
  };

  const getDropdownComponent = (
    bookingPreference: BookingPreference,
    index: number,
  ) => {
    return bookingPreference.isMultipleChoices ? (
      <MultiselectDropdown
        dropdownRef={(el: DropdownInputHandle | null) =>
          (dropdownRefs.current[index] = el!)
        }
        data-hook={
          BookingDetailsDataHooks.DROPDOWN + '-' + bookingPreference.key
        }
        newErrorMessage={isCalendarA11YLabelsEnabled}
        key={dateAndTime + bookingPreference.key + forceDropdownRender}
        className={st(classes.commonMultiselectDropDownStyle, {
          aboveAll: selectedDropDown === bookingPreference,
        })}
        label={
          isCalendarA11YLabelsEnabled ? bookingPreference.label : undefined
        }
        appendTo={preferences?.appendToViewPort ? 'viewport' : undefined}
        optionsContainerClass={cssVarsContainerClassName}
        disabled={bookingPreference.disabled}
        onExpandedChange={() => onDropdownClick(bookingPreference)}
        error={!!bookingPreference.error.message}
        errorMessage={bookingPreference.error.message}
        applyButtonContent={t(
          'app.booking-details.dropdowns.custom-preferences.apply.text',
        )}
        aria-label={bookingPreference.placeholder}
        placeholder={
          isCalendarA11YLabelsEnabled
            ? undefined
            : bookingPreference.placeholder
        }
        note={bookingPreference.note!}
        options={bookingPreference.options.map((option) => ({
          id: option.id!,
          value: option.value!,
          subtitle: option.subtitle!,
          counterOptions: {
            incrementAriaLabel: t(
              'app.booking-details.dropdowns.custom-preferences.increment-aria-label',
              { optionName: option.value },
            ),
            decrementAriaLabel: t(
              'app.booking-details.dropdowns.custom-preferences.decrement-aria-label',
              { optionName: option.value },
            ),
            inputAriaLabel: t(
              'app.booking-details.dropdowns.custom-preferences.input-aria-label',
              { optionName: option.value },
            ),
            max:
              bookingPreference.openSpotsRemained! +
              option.numberOfParticipants!,
            numberOfParticipants: option.numberOfParticipants!,
            onChange: (numberOfParticipants: number) =>
              onDropdownChange(
                option.id!,
                bookingPreference,
                numberOfParticipants,
                true,
              ),
          },
        }))}
      />
    ) : (
      <Dropdown
        newErrorMessage={isCalendarA11YLabelsEnabled}
        label={
          isCalendarA11YLabelsEnabled ? bookingPreference.label : undefined
        }
        upgrade
        ref={(el) => (dropdownRefs.current[index] = el!)}
        initialSelectedId={bookingPreference.preselectedOptionId || '-1'} // WA since ui-tpa does not recognize switching from value to undefined as reset
        error={!!bookingPreference.error.message}
        errorMessage={bookingPreference.error.message}
        data-hook={
          BookingDetailsDataHooks.DROPDOWN + '-' + bookingPreference.key
        }
        className={st(classes.commonDropDownStyle, {
          aboveAll: selectedDropDown === bookingPreference,
        })}
        appendTo={preferences?.appendToViewPort ? 'viewport' : undefined}
        optionsContainerClass={cssVarsContainerClassName}
        disabled={bookingPreference.disabled}
        aria-label={bookingPreference.placeholder}
        placeholder={
          isCalendarA11YLabelsEnabled
            ? undefined
            : bookingPreference.placeholder
        }
        options={bookingPreference.options}
        onExpandedChange={() => onDropdownClick(bookingPreference)}
        key={
          dateAndTime +
          bookingPreference.key +
          forceDropdownRender +
          bookingPreference.preselectedOptionId
        }
        onChange={(selectedOption) =>
          onDropdownChange(selectedOption.id!, bookingPreference, 1, false)
        }
      />
    );
  };

  const hasError = preferences?.bookingPreferences.some(
    (preference) => !!preference.error.message,
  );

  useEffect(() => {
    if (isCalendarA11YLabelsEnabled && hasError) {
      const refIndex = preferences?.bookingPreferences.findIndex(
        (preference) => !!preference.error.message,
      );
      dropdownRefs?.current[refIndex!]?.focus();
    }
  }, [hasError]);

  if (!preferences) {
    return null;
  }

  const { bookingPreferences, titleText, clearText } = preferences;

  return (
    <div
      data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}
      ref={rootRef}
    >
      {showDivider && (
        <Divider
          className={classes.divider}
          data-hook={BookingDetailsDataHooks.DIVIDER}
        />
      )}
      <div className={classes.bookingPreferences}>
        <div className={classes.preferencesTitle}>
          <Text
            data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
            className={classes.commonTitleStyles}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {titleText}
          </Text>
        </div>
        {bookingPreferences.map(
          (bookingPreference: BookingPreference, index) => {
            if (bookingPreference.options.length > 1) {
              return getDropdownComponent(bookingPreference, index);
            }
            return null;
          },
        )}
        {isClearButtonVisible ? (
          <p className={classes.clearPreferences}>
            <TextButton
              data-hook={BookingDetailsDataHooks.CLEAR_BUTTON}
              className={classes.commonLinkStyles}
              onClick={() => clearDropdowns()}
            >
              <span className={classes.textWithEllipsis}>{clearText}</span>
            </TextButton>
          </p>
        ) : null}
      </div>
    </div>
  );
};
