import React from 'react';
import { classes } from './SrOnly.st.css';

export interface SrOnlyProps {
  'data-hook': string;
  id?: string;
  role?: string;
}

export const SrOnly: React.FC<any> = (props) => {
  return <div {...props} className={classes.srOnly} />;
};
