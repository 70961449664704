import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import styleParam from '../BookingCalendar/stylesParams';
import { weeklyTimetableSettingsParamsDefinitions } from './settingsParams';
import { settingsParamsDefinitions } from '../BookingCalendar/settingsParams';
import { generateCustomCssVars } from '../BookingCalendar/stylesParamsUtils';

export default styleParam;

export const customCssVars: CustomCssVarsFn = (args) => {
  return generateCustomCssVars(args, {
    ...settingsParamsDefinitions,
    ...weeklyTimetableSettingsParamsDefinitions,
  });
};
