import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { SrOnly } from '../../../../../../utils/accessibility/SrOnly/SrOnly';
import { DayAvailabilityDataHooks } from './constants';
import { st, classes } from './DayAvailability.st.css';

export interface DayAvailabilityProps {
  date: string;
  isPastDate: boolean;
  accessibility: {
    dayWithoutSlotsSrOnlyText: string;
    dayWithSlotsSrOnlyText: string;
  };
  slots?: React.ReactNode;
  noSlots?: React.ReactNode;
}

export const DayAvailability: React.FC<DayAvailabilityProps> = ({
  accessibility: { dayWithSlotsSrOnlyText, dayWithoutSlotsSrOnlyText },
  date,
  noSlots,
  slots,
  isPastDate,
}) => {
  const noSlotsComponent = noSlots ? (
    <>
      <SrOnly
        data-hook={`${DayAvailabilityDataHooks.DayWithoutSlotsSrOnly}-${date}`}
      >
        {dayWithoutSlotsSrOnlyText}
      </SrOnly>
      <Text
        key={`${DayAvailabilityDataHooks.DayWithoutSlots}-${date}`}
        data-hook={`${DayAvailabilityDataHooks.DayWithoutSlots}-${date}`}
        className={st(classes.noSlots, { isPastDate })}
        aria-hidden={true}
      >
        {noSlots}
      </Text>
    </>
  ) : null;

  return (
    <div className={classes.root}>
      {slots ? (
        <>
          <SrOnly
            data-hook={`${DayAvailabilityDataHooks.DayWithSlotsSrOnly}-${date}`}
          >
            {dayWithSlotsSrOnlyText}
          </SrOnly>
          {slots}
        </>
      ) : (
        noSlotsComponent
      )}
    </div>
  );
};
