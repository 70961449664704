import React from 'react';
import { classes } from './WeekPicker.st.css';
import { WeekPickerViewModel } from '../../../ViewModel/weekPickerViewModel/weekPickerViewModel';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  getLocalDateTimeEndOfDay,
  getLocalDateTimeStartOfDay,
} from '../../../../../utils/dateAndTime/dateAndTime';
import { WeeklyDateNavigation as WeekPickerComponent } from 'wix-ui-tpa/cssVars';
import { DataHooks } from './constants';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';

export type WeekPickerProps = {
  viewModel: WeekPickerViewModel;
};

const WeekPicker: React.FC<WeekPickerProps> = ({ viewModel }) => {
  const { selectedRange, firstDayOfTheWeek, locale, accessibility } = viewModel;
  const { onRangeSet } = useCalendarActions();

  const onRangeChange = ({
    startOfWeek,
    endOfWeek,
  }: {
    startOfWeek: Date;
    endOfWeek: Date;
  }) => {
    const fromAsLocalDateTime = getLocalDateTimeStartOfDay(startOfWeek);
    const toAsLocalDateTime = getLocalDateTimeEndOfDay(endOfWeek);
    onRangeSet({ fromAsLocalDateTime, toAsLocalDateTime });
  };

  return (
    <>
      {accessibility.onRangeSetSrOnlyAnnouncement ? (
        <SrOnly
          id="announcement"
          role="alert"
          data-hook={DataHooks.SrOnlyAnnouncement}
        >
          {accessibility.onRangeSetSrOnlyAnnouncement}
        </SrOnly>
      ) : null}
      <WeekPickerComponent
        data-hook={DataHooks.WeekPicker}
        value={selectedRange}
        onChange={onRangeChange}
        firstDayOfWeek={firstDayOfTheWeek}
        locale={locale}
        nextArrowAriaLabel={accessibility.nextWeekAriaLabel}
        prevArrowAriaLabel={accessibility.prevWeekAriaLabel}
        className={classes.root}
      />
    </>
  );
};

export default WeekPicker;
