import { DatePickerViewModel } from '../../../ViewModel/datePickerViewModel/datePickerViewModel';

export enum DatePickerDataHooks {
  MONTHLY_DATE_PICKER = 'monthly-date-picker',
  WEEKLY_DATE_PICKER = 'weekly-date-picker',
  DOT_ICON = 'dot-icon',
}

export type DatePickerProps = {
  viewModel: DatePickerViewModel;
};
