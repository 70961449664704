import React, { useEffect } from 'react';
import { classes } from './TimeSlotsNotification.st.css';
import { TimeSlotsNotificationViewModel } from '../../ViewModel/timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';

import {
  SectionNotification,
  TextButtonPriority,
  SectionNotificationType as NOTIFICATION_TYPE,
  TextButton,
} from 'wix-ui-tpa/cssVars';

import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { DataHooks } from './constants';
import { WidgetElements } from '../../../../utils/bi/consts';

export type TimeSlotsNotificationProps = {
  viewModel?: TimeSlotsNotificationViewModel;
};

const TimeSlotsNotification: React.FC<TimeSlotsNotificationProps> = ({
  viewModel,
}) => {
  const { onSlotsNotificationCtaClick, onElementLoaded } = useCalendarActions();

  const notificationType = viewModel?.notificationType;

  useEffect(() => {
    if (notificationType) {
      onElementLoaded(WidgetElements.TIME_SLOT_NOTIFICATION, {
        notificationType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationType]);

  return viewModel ? (
    <div className={classes.root}>
      <SectionNotification
        type={NOTIFICATION_TYPE.wired}
        className={classes.notification}
        data-hook={DataHooks.TimeSlotsNotification}
      >
        <SectionNotification.Text className={classes.text}>
          {viewModel.messageText}
          {viewModel.ctaText ? (
            <div>
              <TextButton
                className={classes.cta}
                priority={TextButtonPriority.link}
                data-hook={DataHooks.CTA}
                onClick={() =>
                  onSlotsNotificationCtaClick(viewModel.notificationType!)
                }
              >
                {viewModel.ctaText}
              </TextButton>
            </div>
          ) : null}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  ) : null;
};

export default TimeSlotsNotification;
