import { useContext } from 'react';
import {
  isWeeklyTimeTableWidget,
  isCalendarWidget,
  isDailyAgendaWidget,
} from '../../../utils/presets';
import calendarSettingsParams from '../settingsParams';
import weeklyTimetableSettingsParams from '../../WeeklyTimetable/settingsParams';
import calendarWidgetSettingsParams from '../../BookingCalendarWidget/settingsParams';
import dailyAgendaSettingsParams from '../../DailyAgenda/settingsParams';

import { PresetContext } from './usePreset';

export const useSettingsParams = () => {
  const preset = useContext(PresetContext);

  if (isWeeklyTimeTableWidget(preset)) {
    return weeklyTimetableSettingsParams;
  }

  if (isDailyAgendaWidget(preset)) {
    return dailyAgendaSettingsParams;
  }

  if (isCalendarWidget(preset)) {
    return calendarWidgetSettingsParams;
  }

  return calendarSettingsParams;
};
