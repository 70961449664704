import { createContext, useContext } from 'react';
import {
  isCalendarPage,
  isWeeklyTimeTableWidget,
  isCalendarWidget,
  isDailyAgendaWidget,
} from '../../../utils/presets';
import { Preset } from '../../../types/types';

export const PresetContext = createContext<Preset>(Preset.CALENDAR_PAGE);

export const PresetConsumer = PresetContext.Consumer;
export const PresetProvider = PresetContext.Provider;

export const usePreset = () => {
  const preset = useContext(PresetContext);

  return {
    preset,
    isCalendarPage: isCalendarPage(preset),
    isCalendarWidget: isCalendarWidget(preset),
    isWeeklyTimeTableWidget: isWeeklyTimeTableWidget(preset),
    isDailyAgendaWidget: isDailyAgendaWidget(preset),
  };
};
