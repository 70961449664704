export enum DataHooks {
  ROOT = 'agenda-slot',
  TIME = 'agenda-slot-time',
  TIME_WIDTH_HOLDER = 'agenda-slot-time-holder',
  SERVICE_NAME = 'agenda-slot-service-name',
  AGENDA_SLOT_DETAIL = 'agenda-slot-detail',
  CTA = 'agenda-slot-cta',
  POLICY_VIOLATION = 'agenda-slot-policy-violation',
}

export enum AgendaSlotDetailType {
  STAFF_MEMBER = 'agenda-slot-detail-staff-member',
  DURATION = 'agenda-slot-detail-duration',
  PRICE = 'agenda-slot-detail-price',
  SPOTS_LEFT = 'agenda-slot-detail-spots-left',
  LOCATION = 'agenda-slot-detail-location',
}
