import { useEnvironment } from '@wix/yoshi-flow-editor';

export type UseHistory = typeof useHistory;
export type UseHistoryReturnType = ReturnType<UseHistory>;

export const useHistory = () => {
  const { isSSR } = useEnvironment();

  const length = isSSR ? 1 : window.history.length;
  const back = () => {
    window.history.back();
  };

  return {
    length,
    back,
  };
};
